<template>
  <div>
    <v-skeleton-loader
      v-if="isAppLoading"
      v-bind="attrs"
      type="list-item-two-line, list-item-two-line, list-item-two-line"
    ></v-skeleton-loader>

    <div v-else>
      <v-menu v-if="hasData" bottom close-on-click allow-overflow>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" outlined>
            <v-icon class="mr-2">mdi-sort</v-icon>
            {{ $vuetify.lang.t("$vuetify.base.sort") }} -
            {{ selectedSortOption.title }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item-group
            @change="sort"
            v-model="selectedSort"
            color="primary"
          >
            <v-list-item v-for="(option, index) in sortOptions" :key="index">
              <v-list-item-title>{{ option.title }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <v-select 
        class="mt-5"
        @change="changeProduct" 
        v-model="selectedProductId" 
        v-if="products && products.length > 0" 
        label="Choose a product" outlined dense hide-details
        item-text="name" item-value="id" 
        :items="productOptions"/>

      <v-text-field
        dense
        clearable
        @keyup.enter="filter()"
        @click:clear="
          search = '';
          filter();
        "
        @click:append="filter()"
        class="mb-3 mt-5"
        outlined
        :label="$vuetify.lang.t('$vuetify.base.search')"
        v-model="search"
        @change="filter()"
        append-icon="mdi-magnify"
        v-if="inventories && inventories.length > 5"
      />
      <v-range-slider
        v-if="hasData && dates && dates.length > 4"
        v-model="dateRange"
        :max="maxDate"
        :min="minDate"
        hide-details
        class="align-center mt-4"
        @change="filter()"
        :key="selectedProductId"
      >
        <template v-slot:prepend>
          <div style="white-space: nowrap" class="text-caption">
            {{ dates[dateRange[0]] | formatDateYear }}
          </div>
        </template>
        <template v-slot:append>
          <div style="white-space: nowrap" class="text-caption">
            {{ dates[dateRange[1] - 1] | formatDateYear }}
          </div>
        </template>
      </v-range-slider>

      <v-list v-if="hasData">
        <div v-if="selectedProduct" class="text-overline text-center">
          {{ selectedProduct.name }} -
          {{
            $vuetify.lang.t(
              "$vuetify.worksheet.xAvailable",
              filteredInventories ? filteredInventories.length : 0
            )
          }}
        </div>
        <v-list-item-group color="primary">
          <v-list-item
            class="pl-0 pl-sm-2 pl-md-4"
            v-for="(inventory, index) in filteredInventories"
            :key="inventory.id"
            :disabled="(inventory.quantityAvailable - inventory.reservedQuantity) <= 0"
            @click="selectedInventoryChanged(inventory)"
          >
            <v-list-item-icon class="mr-2 mr-md-4"
              >{{ index + 1 }}.</v-list-item-icon
            >
            <v-list-item-content>
              <v-list-item-title>
                <div class="d-flex">
                  <span v-if="products && products.length > 0">{{ inventory.productName}}</span>
                  <v-divider v-if="products && products.length > 0" vertical class="mx-2"/>
                  <span>
                  {{ inventory.quantityAvailable - inventory.reservedQuantity | formatNumber }}
                  {{ selectedProduct ? selectedProduct.measureWeight : "" }}
                  </span>
                </div>
                <span class="text-caption" v-if="inventory.reserved"
                  > ({{inventory.reservedQuantity | formatNumber }} {{ selectedProduct ? selectedProduct.measureWeight : "" }} {{ $vuetify.lang.t("$vuetify.worksheet.reserved") }}) </span
                >
                <span
                  class="text-caption font-weight-light"
                  v-if="
                    inventory.unitDetails && inventory.unitDetails.length > 0
                  "
                >
                  ({{
                    $vuetify.lang.t(
                      "$vuetify.worksheet.xAvailable",
                      inventory.unitAvailable
                    )
                  }})</span
                >
                <span
                  class="text-caption font-weight-light"
                  v-if="
                    inventory.unitDetails && inventory.unitDetails.length > 0 
                    && inventory.reservedByWorksheets && inventory.reservedByWorksheets.length > 0
                  "
                >
                  ({{
                    $vuetify.lang.t(
                      "$vuetify.worksheet.xReserved",
                      inventory.reservedByWorksheets.length
                    )
                  }})
                  </span
                >
              </v-list-item-title>
              <v-list-item-subtitle>
                <div class="d-flex">
                  {{ inventory.stockTime | formatDateYear }}
                  (Doc #: {{ inventory.documentNumber }} / ID:
                  {{ inventory.id }})
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <div class="my-8" v-else>No data found</div>
    </div>

    <v-bottom-sheet scrollable v-model="showUnits">
      <v-sheet class="text-center" style="max-height: 380px; overflow: scroll">
        <v-btn class="mt-6" text color="red" @click="showUnits = false">
          {{ $vuetify.lang.t("$vuetify.close") }}
        </v-btn>
        <v-divider />
        <div class="mx-6">
          <v-text-field
            dense
            clearable
            @keyup.enter="filterUnit()"
            @click:clear="
              searchUnit = '';
              filterUnit();
            "
            @click:append="filterUnit()"
            outlined
            hide-details
            label="Search Unit"
            v-model="searchUnit"
            @change="filterUnit()"
            append-icon="mdi-magnify"
            v-if="units && units.length > 5"
          />
          <v-switch
            @change="filterUnit()"
            hide-details
            dense
            v-model="showUsed"
            label="Show used"
          />
        </div>
        <div class="text-overline">
          {{ $vuetify.lang.t("$vuetify.worksheet.chooseUnit") }}
        </div>

        <v-list v-if="selectedInventory">
          <v-list-item-group>
            <v-list-item
              v-for="(unit, unitIndex) in filteredUnits"
              :key="unit.id"
              :disabled="unit.reserved || unit.status == 'Used'"
              @click="selectedInventoryUnitChanged(unit)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ unitIndex + 1 }}. {{ unit.code }}
                  <span class="font-weight-bold ml-5"
                    >{{ unit.amountPerUnit | formatNumber }}
                    {{ unit.unitMeasure }}</span
                  >
                  <span
                    class="text-caption font-weight-light"
                    v-if="unit.status == 'Used'"
                  >
                    ({{ $vuetify.lang.t("$vuetify.worksheet.used") }})</span
                  >
                  <span
                    class="text-caption font-weight-light"
                    v-else-if="unit.reserved"
                  >
                    ({{ $vuetify.lang.t("$vuetify.worksheet.reserved") }})</span
                  >
                </v-list-item-title>
                <v-list-item-subtitle v-if="unit.description">
                  {{ unit.description }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { tagReservedInventories } from "@/services/inventoryReservation.js";

export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },
    products: {
      type: Array,
      required: false,
    },
    productId: {
      type: Number,
      required: true,
    },
  },
  data: () => {
    return {
      selectedProductId: null,
      selectedProduct: null,
      inventories: [],
      filteredInventories: [],
      filteredUnits: [],
      selectedInventory: null,
      selectedInventoryUnit: null,
      units: [],
      dates: [],
      dateRange: [],
      minDate: 0,
      maxDate: 0,
      search: null,
      searchUnit: null,
      selectedSort: 0,
      showUnits: false,
      showUsed: false,
      productOptions: []
    };
  },
  watch: {
    showUnits(val) {
      if (!val) {
        this.selectedInventory = null;
      }
    },
  },
  computed: {
    ...mapGetters("navigation", ["isAppLoading"]),
    productName() {
      if (this.inventories && this.inventories.length > 0) {
        return this.inventories[0].productName;
      } else {
        return null;
      }
    },
    selectedSortOption() {
      return this.sortOptions[this.selectedSort];
    },
    hasData() {
      return this.filteredInventories && this.filteredInventories.length > 0;
    },
    sortOptions() {
      return [
        {
          title: `${this.$vuetify.lang.t(
            "$vuetify.base.date"
          )} ${this.$vuetify.lang.t("$vuetify.base.sortDescending")}`,
          field: "stockTime",
          order: "desc",
        },
        {
          title: `${this.$vuetify.lang.t(
            "$vuetify.base.date"
          )} ${this.$vuetify.lang.t("$vuetify.base.sortAscending")}`,
          field: "stockTime",
          order: "asc",
        },
        {
          title: `${this.$vuetify.lang.t(
            "$vuetify.base.weight"
          )} ${this.$vuetify.lang.t("$vuetify.base.sortDescending")}`,
          field: "quantityAvailable",
          order: "desc",
        },
        {
          title: `${this.$vuetify.lang.t(
            "$vuetify.base.weight"
          )} ${this.$vuetify.lang.t("$vuetify.base.sortAscending")}`,
          field: "quantityAvailable",
          order: "asc",
        },
      ];
    },
    states: {
      get() {
        return this.$store.state.inventorySelection.states;
      },
      set(value) {
        this.updateStates(value);
      },
    },
  },
  async mounted() {
    this.selectedProductId = this.productId;

    this.productOptions = [...[{id: null, name: 'All Products'}], ...this.products];

    if(this.products && this.products.length > 0 && !this.selectedProductId) {
      this.selectedProductId = null;
    }

    if (this.states.productId != this.selectedProductId) {
      this.resetInventorySelectionStates();
      this.states.productId = this.selectedProductId;
    } else {
      this.dateRange = this.states.dateRange;
      this.search = this.states.search;
      this.selectedSort = this.states.selectedSort;
    }

    if(this.states.productId){
      await this.loadCategory(this.states.productId)
        .then((product) => {
          this.selectedProduct = product;
        })
    }

    this.updateAvailableInventory();
  },
  methods: {
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("inventorySelection", [
      "updateStates",
      "resetInventorySelectionStates",
    ]),
    ...mapActions("categoryStore", ["fetchCategory"]),


    async getAvailableProduct(pIds) {
      if(!pIds || pIds.length < 1){
        return [];
      }
      const ids = pIds.join(",");
      return await this.$axios
        .get(`/inventory/available-by-product`, {params: {productIds: ids }})
        .then((response) => {
          const availableInventories = response.data;
          //Set document number
          if (availableInventories && availableInventories.length > 0) {
            availableInventories.forEach((i) => {
              if (i.fields && i.fields.length > 0) {
                const docNumberField = i.fields.find((f) =>
                  f.field.toLowerCase().includes("document no")
                );
                i.documentNumber = docNumberField
                  ? docNumberField.fieldValueString
                  : "";
              }
            });
          }
          return availableInventories;
        });
    },
    async loadCategory(id) {
      if(id){
        return await this.fetchCategory(id)
          .then((category) => {
            return category;
          })
          .catch((error) => {
            this.addErrorMessage("Item not found; " + error.data.message);
            return null;
          });
      }
      else{ return null }
    },
    updateAvailableInventory(){
      let productIds = []
      if(this.states.productId){
        productIds.push(this.states.productId);
      }
      else{
        productIds = this.products.map((p) => p.id);
      }

      this.getAvailableProduct(productIds).then(async (inventories) => {
        this.inventories = inventories;
        await tagReservedInventories(this.inventories).then(() => {
          this.aggregateDates(inventories);
          this.filter();
          this.sort();
        });
      });
    },
    async changeProduct(){
        this.resetInventorySelectionStates();
        this.states.productId = this.selectedProductId;
        this.dateRange = this.states.dateRange;
        this.search = this.states.search;
        this.selectedSort = this.states.selectedSort;

        if(this.states.productId){
          await this.loadCategory(this.states.productId)
            .then((product) => {
              this.selectedProduct = product;
            })
        }

        this.updateAvailableInventory();
    },
    filter() {
      this.states.search = this.search;
      this.states.dateRange = this.dateRange;
      this.states.selectedSort = this.selectedSort;

      let filtered = [];
      if (this.inventories && this.inventories.length > 0) {
        const minDate = new Date(this.dates[this.dateRange[0]]);
        const maxDate = new Date(this.dates[this.dateRange[1] - 1]);
        filtered = this.inventories.filter((i) => {
          var b = i.stockTime.split(/\D/);
          const date = `${b[0]}-${b[1]}-${b[2]}`;
          const stockDate = new Date(date); // filter stock date within range

          let matchSearch = true;
          if (this.search) {
            const searchString = `${
              i.quantityAvailable
            } ${this.$options.filters.formatDateYear(i.stockTime)} ${
              i.documentNumber
            } ${i.id}`;
            matchSearch = searchString
              .toLowerCase()
              .includes(this.search.trim().toLowerCase());
          }
          // const reserved = i.reserved; // filter reserved inventories
          return stockDate >= minDate && stockDate <= maxDate && matchSearch;
        });
      } else {
        filtered = this.inventories;
      }

      this.filteredInventories = filtered;
    },
    filterUnit() {
      let filtered = [];
      if (this.units && this.units.length > 0) {
        filtered = this.units;

        if (!this.showUsed) {
          filtered = this.units.filter((u) => u.status != "Used");
        }

        filtered = filtered.filter((u) => {
          let matchSearch = true;
          if (this.searchUnit) {
            const searchString = `${u.code} ${u.amountPerUnit}`;
            matchSearch = searchString
              .toLowerCase()
              .includes(this.searchUnit.trim().toLowerCase());
          }
          return matchSearch;
        });
      } else {
        filtered = this.units;
      }

      this.filteredUnits = filtered;
    },
    sort() {
      if (this.filteredInventories) {
        const sortBy = this.selectedSortOption.field;
        const sortOrder = this.selectedSortOption.order;
        this.filteredInventories.sort((a, b) => {
          if (sortBy == "stockTime") {
            const aDate = new Date(a.stockTime);
            const bDate = new Date(b.stockTime);
            return sortOrder == "desc" ? bDate - aDate : aDate - bDate;
          } else if (sortBy == "quantityAvailable") {
            return sortOrder == "desc"
              ? b.quantityAvailable - a.quantityAvailable
              : a.quantityAvailable - b.quantityAvailable;
          }
        });
      }
    },
    aggregateDates(inventories) {
      //parse date min/max
      const dates = new Set();
      inventories.forEach((u) => {
        var b = u.stockTime.split(/\D/);
        const date = `${b[0]}-${b[1]}-${b[2]}`;
        dates.add(date);
      });

      this.dates = [...dates].sort();
      if (this.dateRange == null || this.dateRange.length < 1) {
        this.dateRange = [0, this.dates.length];
      }
      this.minDate = 0;
      this.maxDate = this.dates.length;
    },
    selectedInventoryChanged(inventory) {
      this.selectedInventory = inventory;
      this.selectedInventory = inventory;

      if (
        this.selectedInventory &&
        this.selectedInventory.unitDetails &&
        this.selectedInventory.unitDetails.length > 0
      ) {
        //open inventory unit details
        this.units = [];
        this.showUnits = true;
        this.loadInventoryUnitDetails();
      } else {
        //select inventory
        this.$emit("input", this.selectedInventory);
        this.$emit("change", this.selectedInventory);
      }
    },
    selectedInventoryUnitChanged(unit) {
      this.selectedInventoryUnit = unit;
      console.log("selected inventory unit");
      this.$emit("input", this.selectedInventory);
      this.$emit("change", this.selectedInventory, this.selectedInventoryUnit);
    },
    async loadInventoryUnitDetails() {
      return await this.$axios(
        "inventory/unit-details/by-inventory/" + this.selectedInventory.id
      ).then((response) => {
        const respUnits = response.data;
        if (respUnits) {
          //set reserved info for unit
          respUnits.forEach((unit) => {
            const inventoryUnit = this.selectedInventory.unitDetails.find(
              (u) => u.id == unit.id
            );
            unit.reserved = inventoryUnit.reserved;
          });
        }

        this.units = respUnits;

        this.filterUnit();
      });
    },
  },
};
</script>

<style></style>
